import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { IPerformanceProFilterOptions } from "../../../store/GlobalContext";
import { onlyUnique } from "../../../utility/ArrayUtility";
import { determineYAxisMax } from "../../../utility/ChartsUtility";
import { DatePeriod } from "../../../utility/DateUtility";
import {
  EggUnit,
  GetEggUnitTranslation,
  GetEggUnitWithPrefix,
} from "../../../utility/EggUnitUtility";
import { createUnitsConverter, EggType } from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { PerformanceGroupItem } from "../Types";
import { DetermineTooltipFormatter } from "./DetermineTooltipFormatter";
import {
  StackedBarChartGroupMapping,
  StackedBarChartMapping,
  XAxisType,
} from "./GroupsChartMapping";
import { StackBarChartBaseOptions } from "./StackedBarBaseChartOptions";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

export interface StackedBarBaseChartProps {
  data: PerformanceGroupItem[];
  options: IPerformanceProFilterOptions;
  chartMapping: StackedBarChartMapping;
  eggUnit: EggUnit;
  usePercentage: boolean;
  totalsPerX: PerformanceGroupItem[];
  useGroupTotals: boolean;
  onBarSupplyClicked: Nullable<
    (supplierName: string, supplierShed: string) => void
  >;
}

interface ChartSeries {
  name: string;
  type: string;
  data: (string | number)[][];
  originalName: string;
}

type PerformanceGroupItemDateAsNumber = {
  x: string | number;
  group: string;
  value: number;
  eggType: EggType;
};

export function StackedBarBaseChart({
  data,
  options,
  chartMapping,
  eggUnit,
  usePercentage,
  totalsPerX,
  useGroupTotals,
  onBarSupplyClicked,
}: StackedBarBaseChartProps) {
  const newOptions = useMemo(() => {
    let isDateSeries = false;
    let series: ChartSeries[] = [];
    let yAxisMax: Nullable<number> = null;

    if (data !== null && data.length !== 0) {
      const eggUnitConverter = createUnitsConverter(eggUnit);

      isDateSeries = data[0].x instanceof Date;
      const dataWithXDateAsTimeOrString: PerformanceGroupItemDateAsNumber[] =
        isDateSeries
          ? data.map(transformXToTime)
          : (data as PerformanceGroupItemDateAsNumber[]);
      const totalsPerXWithXDateAsTimeOrString: PerformanceGroupItemDateAsNumber[] =
        isDateSeries
          ? totalsPerX.map(transformXToTime)
          : (totalsPerX as PerformanceGroupItemDateAsNumber[]);

      series = determineGroupedSeries(
        dataWithXDateAsTimeOrString,
        totalsPerXWithXDateAsTimeOrString,
        useGroupTotals,
        chartMapping,
        eggUnitConverter,
        usePercentage
      );

      if (usePercentage) {
        const totalPercentagesPerX: (string | number)[][] =
          getTotalPercentagesOfData(series);
        yAxisMax = determineYAxisMax(totalPercentagesPerX, 100);
      }
    }

    let categories: (string | Date)[] | undefined = undefined;
    if (chartMapping.xAxisType === XAxisType.Supply) {
      categories = data
        .map((item) => item.x as string)
        .filter(onlyUnique)
        .sort((a, b) => a.localeCompare(b));
    }

    return {
      ...StackBarChartBaseOptions(
        options,
        chartMapping.xAxisType,
        onBarSupplyClicked
      ),
      series: series,
      colors: mapColors(chartMapping, series),
      yAxis: {
        ...StackBarChartBaseOptions(
          options,
          chartMapping.xAxisType,
          onBarSupplyClicked
        ),
        max: yAxisMax,
        title: {
          text: GetEggUnitWithPrefix(usePercentage, eggUnit),
        },
      },
      xAxis: {
        ...StackBarChartBaseOptions(
          options,
          chartMapping.xAxisType,
          onBarSupplyClicked
        ).xAxis,
        dateTimeLabelFormats: isDateSeries
          ? {
              day:
                options.selectedDatePeriod === DatePeriod.Year
                  ? "%b %y"
                  : "%e %b %y",
              month: "%b %y",
            }
          : undefined,
        type: isDateSeries ? "datetime" : "category",
        categories: categories,
      },
      tooltip: {
        ...DetermineTooltipFormatter(
          options,
          chartMapping,
          isDateSeries,
          usePercentage,
          GetEggUnitTranslation(eggUnit)
        ),
        shared: true,
      },
      exporting: {
        enabled: !isMobile,
        buttons: {
          contextButton: {
            menuItems: [
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
              "separator",
              "downloadCSV",
              "downloadXLS",
            ],
          },
        },
      },
    };
  }, [
    data,
    chartMapping,
    options,
    onBarSupplyClicked,
    usePercentage,
    eggUnit,
    totalsPerX,
    useGroupTotals,
  ]);

  return <HighchartsReact highcharts={Highcharts} options={newOptions} />;
}

const transformXToTime = (
  item: PerformanceGroupItem
): PerformanceGroupItemDateAsNumber => {
  return { ...item, x: (item.x as Date).getTime() };
};

function getSeriesGroupName(
  chartMapping: StackedBarChartMapping,
  groupKey: any
): string {
  const label = chartMapping.groupMapping.find(
    (mapping: StackedBarChartGroupMapping) =>
      mapping.name.toLowerCase() === groupKey.toLowerCase()
  )?.label;
  //FIXME: this doesn't always translate, sometimes this already contains the correct serie name
  return t(label);
}

/**
 * HighCharts displays colors using the options.colors array.
 * These colors are rendered in order of that array.
 *
 * Sometimes, the data does not contain the group that is included in the groupMapping array.
 * An example is the blood detector of a machine. Not all machines have this option and thus the data will not contain this grouping.
 * We should make sure the colors array is in order of the series array and does not contain any colors that are not meant to be rendered.
 *
 * @param chartMapping
 * @param series
 * @returns the array of colors to render
 */
function mapColors(
  chartMapping: StackedBarChartMapping,
  series: any
): Array<string> {
  const seriesNames = series.map((item: any) => item.originalName);
  let result: Array<string> = [];

  for (const seriesName of seriesNames) {
    const found = chartMapping.groupMapping.find(
      (mapping) => mapping.name === seriesName
    );

    if (found === undefined || found.color === undefined) {
      result.push("#000000");
      continue;
    }

    result.push(found.color);
  }

  return result;
}

function returnNumberOr0IfInvalid(input: number) {
  return !Number.isNaN(input) && Number.isFinite(input) ? input : 0;
}

function getTotalPercentagesOfData(
  seriesData: ChartSeries[]
): (string | number)[][] {
  let result: (string | number)[][] = [];
  seriesData
    .flatMap((serieItem: ChartSeries) => serieItem.data)
    .forEach((serieDataItem: (string | number)[]) => {
      if (serieDataItem != null) {
        const serieKey = serieDataItem[0];
        const serieValue = serieDataItem[1] as number;

        const indexOfExisting = result.findIndex(
          (resultItem) => resultItem[0] === serieKey
        );
        if (indexOfExisting !== -1) {
          (result[indexOfExisting][1] as number) +=
            returnNumberOr0IfInvalid(serieValue);
        } else {
          result.push([serieKey, serieValue]);
        }
      }
    });
  return result;
}

function determineGroupedSeries(
  data: PerformanceGroupItemDateAsNumber[],
  totalsPerX: PerformanceGroupItemDateAsNumber[],
  useGroupTotals: boolean,
  chartMapping: StackedBarChartMapping,
  eggUnitConverter: (value: number) => number,
  usePercentage: boolean
): ChartSeries[] {
  const groupByProperty: keyof PerformanceGroupItemDateAsNumber = "group";
  const groupedData: Dictionary<PerformanceGroupItemDateAsNumber[]> = _.groupBy(
    data,
    groupByProperty
  );

  const allSeries: ChartSeries[] = [];

  let totalsInEggsPerX: { [x: string]: number } | undefined = undefined;
  if (usePercentage) {
    totalsInEggsPerX = useGroupTotals
      ? calculateTotalsPerGroup(groupedData)
      : getTotalsFromTotalsPerX(data, totalsPerX);
  }

  for (const [groupKey, groupData] of Object.entries(groupedData)) {
    const groupName = getSeriesGroupName(chartMapping, groupKey);

    if (groupName !== "") {
      const groupSeriesData: (string | number)[][] = mapGroupPeriods(
        groupData,
        totalsInEggsPerX,
        eggUnitConverter,
        usePercentage
      );

      //Sort this group's series data by x
      groupSeriesData.sort((a, b) => (a[0] > b[0] ? 1 : -1));

      const result = {
        name: groupName,
        type: "column",
        data: groupSeriesData,
        originalName: groupKey,
      };
      allSeries.push(result);
    }
  }

  return SortSeriesByGroupingNameOrder(allSeries, chartMapping.groupMapping);
}

function mapGroupPeriods(
  groupPeriods: PerformanceGroupItemDateAsNumber[],
  totalsInEggsPerX: { [x: string]: number } | undefined,
  eggUnitConverter: (amount: number) => number,
  usePercentage: boolean
): (string | number)[][] {
  return groupPeriods.map((item: PerformanceGroupItemDateAsNumber) => [
    item.x,
    getSerieValue(item, totalsInEggsPerX, eggUnitConverter, usePercentage),
  ]);
}

function getSerieValue(
  item: PerformanceGroupItemDateAsNumber,
  totalsInEggsPerX: { [x: string]: number } | undefined,
  eggUnitConverter: (amount: number) => number,
  usePercentage: boolean
): number {
  if (usePercentage && totalsInEggsPerX !== undefined) {
    const denominator = totalsInEggsPerX[item.x];
    return denominator !== 0
      ? (item.value / totalsInEggsPerX[item.x]) * 100.0
      : 0;
  } else {
    return eggUnitConverter(item.value);
  }
}

function SortSeriesByGroupingNameOrder(
  chartSeries: ChartSeries[],
  groupMapping: StackedBarChartGroupMapping[]
): ChartSeries[] {
  const mappingIndexesByName: Dictionary<number> = {};
  groupMapping.forEach(
    (mapping, index) => (mappingIndexesByName[mapping.name] = index)
  );

  return [
    ...chartSeries.sort((a, b) =>
      mappingIndexesByName[a.originalName] <
      mappingIndexesByName[b.originalName]
        ? -1
        : 1
    ),
  ];
}

/**
 * x: supplier-name-1,
 * group: blood
 * value: 10
 * eggType: Organic
 *
 * =>
 * supplier-name-1: 10
 *
 * @returns The percentage of the egg type in comparison to the total of all eggs.
 */
function getTotalsFromTotalsPerX(
  data: PerformanceGroupItemDateAsNumber[],
  totalsPerX: PerformanceGroupItemDateAsNumber[]
): { [x: string]: number } {
  const totals: { [x: string]: number } = {};
  const xKeys: (string | number)[] = data
    .map((item) => item.x)
    .filter(onlyUnique);

  for (const xKey of xKeys) {
    const totalOfPerformanceGroupItem:
      | PerformanceGroupItemDateAsNumber
      | undefined = totalsPerX.find((item) => item.x === xKey);
    totals[xKey] = totalOfPerformanceGroupItem?.value ?? 0;
  }

  return totals;
}

function calculateTotalsPerGroup(
  groupedData: Dictionary<PerformanceGroupItemDateAsNumber[]>
): { [x: string]: number } {
  const totals: { [x: string]: number } = {};

  for (const [, groupData] of Object.entries(groupedData)) {
    groupData.forEach((item: PerformanceGroupItemDateAsNumber) => {
      const xKey = item.x;
      if (totals[xKey] !== undefined) {
        totals[xKey] += item.value;
      } else {
        totals[xKey] = item.value;
      }
    });
  }

  return totals;
}
