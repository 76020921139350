import { CircularProgress, makeStyles } from "@material-ui/core";
import { ReportView } from "./Common";
import { ReportPerHourContainer } from "./ReportPerHourContainer";
import { ReportSummaryPresentation } from "./ReportSummaryPresentation";

interface Props {
  reportView: ReportView | undefined;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export function ReportPresentation({ reportView, isLoading }: Props) {
  const classes = useStyles();

  return (
    <>
      {isLoading || reportView == null || reportView == undefined ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.content}>
            <ReportSummaryPresentation reportView={reportView} />
          </div>
          <div className={classes.content}>
            <ReportPerHourContainer reportView={reportView} />
          </div>
        </>
      )}
    </>
  );
}
