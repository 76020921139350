import { useReportData } from "./Hooks";
import { ReportPresentation } from "./ReportPresentation";

export function ReportContainer() {
  const report = useReportData();

  return (
    <ReportPresentation
      reportView={report.data?.data}
      isLoading={report.isLoading || report.isFetching}
    />
  );
}
