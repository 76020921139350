import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import _ from "lodash";
import { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { GroupMetadata } from "../../../api/PerformancePro/IPerformanceProService";
import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { GetSupplierNameAndShedURI } from "../../../utility/SupplyUtility";
import { t } from "../../../utility/TranslateUtility";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";
import { PerformanceGroupItem } from "../Types";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

export interface ShellQualityChartProps {
  offgradeData: PerformanceGroupItem[];
  totalEggsData: PerformanceGroupItem[];
  totalWeightData: PerformanceGroupItem[];
  metadata: GroupMetadata;
}

export function ShellQualityChart({
  offgradeData,
  totalEggsData,
  totalWeightData,
  metadata,
}: ShellQualityChartProps) {
  const browserHistory = useHistory();

  const newOptions = useMemo(() => {
    const offgradeSeries = getSeries(
      offgradeData,
      totalEggsData,
      totalWeightData,
      metadata
    );

    const minX = Math.min(
      ...[...offgradeSeries[0].data, ...offgradeSeries[1].data].map((x) => x.x)
    );
    const referenceSeries = getReferenceLine(offgradeSeries);

    return {
      ...ShellQualityChartOptions,
      xAxis: {
        ...ShellQualityChartOptions.xAxis,
        min: minX,
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          events: {
            click: function (event: any) {
              const supplierShedName = event.point.options
                .supplierShedName as string;

              let supplierNameAndShedURI =
                GetSupplierNameAndShedURI(supplierShedName);

              browserHistory.push(supplierNameAndShedURI);
            },
          },
        },
      },
      series: [...offgradeSeries, referenceSeries],
      exporting: {
        enabled: !isMobile,
        buttons: {
          contextButton: {
            menuItems: [
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
              "separator",
              "downloadCSV",
              "downloadXLS",
            ],
          },
        },
      },
    };
  }, [offgradeData, totalEggsData, totalWeightData]);

  return <HighchartsReact highcharts={Highcharts} options={newOptions} />;
}

function getSeries(
  offgradeData: PerformanceGroupItem[],
  totalEggsData: PerformanceGroupItem[],
  totalWeightData: PerformanceGroupItem[],
  metadata: GroupMetadata
) {
  const supplyOffgradeData = _.groupBy(offgradeData, "x");

  return [
    getOffgradeSeries(
      supplyOffgradeData,
      totalEggsData,
      totalWeightData,
      "Crack",
      metadata
    ),
    getOffgradeSeries(
      supplyOffgradeData,
      totalEggsData,
      totalWeightData,
      "Leaker",
      metadata
    ),
  ];
}

function getOffgradeSeries(
  supplyOffgradeData: _.Dictionary<PerformanceGroupItem[]>,
  totalEggsData: PerformanceGroupItem[],
  totalWeightData: PerformanceGroupItem[],
  offgradeType: string,
  metadata: GroupMetadata
) {
  const series =
    offgradeType === "Crack" ? getEmptyCrackSeries() : getEmptyLeakerSeries();

  if (totalEggsData.length <= 0 || totalWeightData.length <= 0) return series;

  for (const supply in supplyOffgradeData) {
    const offgradeTypeForSupply = supplyOffgradeData[supply].find(
      (x) => x.group === offgradeType
    );

    const totalEggsForSupply = totalEggsData.find(
      (x) => x.x.toString() === supply
    );

    const totalWeightForSupply = totalWeightData.find(
      (x) => x.x.toString() === supply
    );

    if (!totalEggsForSupply || !totalWeightForSupply) continue;

    const totalWeightInGram = totalWeightForSupply.value / 1000;
    const averageWeightForSupply = totalWeightInGram / totalEggsForSupply.value;

    const supplyInfo = metadata.xAxis.values[supply];

    if (!offgradeTypeForSupply) {
      series.data.push({
        supplierShedName: supplyInfo ? supplyInfo.supplierNameShed : supply,
        x: averageWeightForSupply,
        y: 0,
      });
      continue;
    }

    const offgradeTypePercentagePercentageForSupply =
      (offgradeTypeForSupply.value / totalEggsForSupply.value) * 100;

    series.data.push({
      supplierShedName: supplyInfo ? supplyInfo.supplierNameShed : supply,
      x: averageWeightForSupply,
      y: offgradeTypePercentagePercentageForSupply,
    });
  }

  return series;
}

function getReferenceLine(offgradeSeries: OffgradeSeries[]) {
  const offgradesCrack = offgradeSeries[0];
  const maxOffgradeCrack = Math.max(...offgradesCrack.data.map((x) => x.y));
  const offgradesCrackWPercentage = offgradesCrack.data.map((x) => ({
    ...x,
    percentage: maxOffgradeCrack == 0 ? 0 : (x.y / maxOffgradeCrack) * 100,
  }));

  const offgradesLeaker = offgradeSeries[1];
  const maxOffgradeLeaker = Math.max(...offgradesLeaker.data.map((x) => x.y));
  const offgradesLeakerWPercentage = offgradesLeaker.data.map((x) => ({
    ...x,
    percentage: maxOffgradeLeaker == 0 ? 0 : (x.y / maxOffgradeLeaker) * 100,
  }));

  const offgradesWPercentage = [
    ...offgradesCrackWPercentage,
    ...offgradesLeakerWPercentage,
  ];
  offgradesWPercentage.sort((a, b) => {
    if (a.percentage === b.percentage) {
      return 0;
    }

    return a.percentage > b.percentage ? 1 : -1;
  });
  const offgradesWPercentag90 = offgradesWPercentage.slice(
    0,
    offgradesWPercentage.length * 0.9
  );
  const last = offgradesWPercentag90[offgradesWPercentag90.length - 1];
  const axis =
    offgradesCrackWPercentage.findIndex((x) => x === last) !== -1
      ? "crack"
      : "leaker";

  const maxX = Math.max(
    ...[...offgradeSeries[0].data, ...offgradeSeries[1].data].map((x) => x.x)
  );

  const referenceLineMaxY = last ? last.y : 0;

  return {
    type: "line",
    name: t("uniqueViews.shellQuality.referenceLine"),
    enableMouseTracking: false,
    marker: {
      enabled: false,
    },
    color: "red",
    data: [
      [40, 0],
      [maxX, referenceLineMaxY],
    ],
    yAxis: axis,
  };
}

type OffgradeSeries = {
  yAxis: offgradeAxis;
  color: string;
  name: string;
  data: OffgradeSeriesData[];
};

type OffgradeSeriesData = {
  x: number;
  y: number;
};

type offgradeAxis = "leaker" | "crack";

const getEmptyCrackSeries: () => any = () => {
  return {
    name: t("uniqueViews.shellQuality.crack"),
    yAxis: "crack",
    color: "#6e7ca3",
    data: [],
  };
};

const getEmptyLeakerSeries: () => any = () => {
  return {
    name: t("uniqueViews.shellQuality.leaker"),
    color: "#f9b341",
    yAxis: "leaker",
    data: [],
  };
};

export const ShellQualityChartOptions = {
  chart: {
    type: "scatter",
    spacingLeft: 0,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: { events: {}, turboThreshold: 0 },
    scatter: {
      cursor: "pointer",
      point: { events: {} },
      marker: {
        radius: 5,
        states: { hover: { enabled: true, lineColor: "rgb(100,100,100)" } },
      },
      states: { hover: { marker: { enabled: false } } },
    },
  },
  title: {
    text: "",
  },
  tooltip: getTooltipFormatter(),
  xAxis: {
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true,
    isX: true,
    index: 0,
    title: {
      enabled: true,
      text: t("uniqueViews.shellQuality.averageWeight"),
    },
  },
  yAxis: [
    {
      id: "crack",
      index: 1,
      title: {
        text: t("uniqueViews.shellQuality.crack"),
      },
    },
    {
      id: "leaker",
      index: 0,
      opposite: true,
      title: {
        text: t("uniqueViews.shellQuality.leaker"),
      },
    },
  ],
};

function getTooltipFormatter() {
  return new TooltipBuilder()
    .add(
      (data: {
        point: { supplierShedName: string };
        series: any;
        y: number;
      }) => {
        let parts = "";

        if (!data || !data.series) return parts;
        parts += format(
          {
            label: `<span style="color:${data.series.color}">\u25CF</span>`,
            separator: false,
          },
          data.series.name
        );

        parts += format({}, FormatWithTwoDecimals(data.y) + "%");

        parts += format(
          {
            label: t("uniqueViews.shellQuality.supplierName"),
            separator: true,
          },
          data.point.supplierShedName
        );

        return parts;
      }
    )
    .buildSharedFormatter();
}
