import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormatEggs } from "../../../utility/EggUtility";
import { ReportView } from "./Common";

interface ReportPresentationProps {
  reportView: ReportView | undefined;
}

const BorderedTableCell = withStyles({
  root: {
    border: "1px solid lightgrey",
  },
})(TableCell);

const useStyles = makeStyles({
  header: {
    marginBottom: "10px",
  },
  table: {
    minWidth: 650,
  },
});

export function ReportSummaryPresentation({
  reportView,
}: ReportPresentationProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.header} variant="h5">
        {t("performanceProReportPage.summaryHeader")}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <BorderedTableCell>
                {t("performanceProReportPage.summaryFields.machineId")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.summaryFields.eggsGraded")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.summaryFields.targetPerHour")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.summaryFields.percentageVsTarget")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t(
                  "performanceProReportPage.summaryFields.avgerageEggsPerHour"
                )}
              </BorderedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <BorderedTableCell>{reportView?.machineId}</BorderedTableCell>
              <BorderedTableCell>{reportView?.sumEggsGraded}</BorderedTableCell>
              <BorderedTableCell>
                {reportView?.targetEggsPerHour}
              </BorderedTableCell>
              <BorderedTableCell>
                {FormatEggs(reportView!.avgPercentageVsTarget, null)}
              </BorderedTableCell>
              <BorderedTableCell>
                {reportView?.avgEggsPerHour}
              </BorderedTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
