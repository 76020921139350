import * as Highcharts from "highcharts";
import HC_patternFill from "highcharts-pattern-fill";
import HighchartsReact from "highcharts-react-official";
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import { useMemo } from "react";
import { colors } from "../../colors";
import { IPerformanceProFilterOptions } from "../../store/GlobalContext";
import { DatePeriod } from "../../utility/DateUtility";
import { t } from "../../utility/TranslateUtility";
import {
  MachineStopsGroup,
  MachineStopsPeriod,
} from "../PerformancePro/MachineStopsHook";
import {
  format,
  getDaysAndHoursAndMinutesAndSecondsFromMinutes,
  getDaysAndHoursAndMinutesAndSecondsLabel,
  TooltipBuilder,
} from "../ProductionViews/Tooltip";
import { defaultExportButtonSettings } from "./ChartSettings/BarChartSettings";

HC_patternFill(Highcharts);
HC_exporting(Highcharts);
HC_export_data(Highcharts);

export default function MachineStopsChart({
  groups,
  options,
}: MachineStopsChartProps) {
  const machineStopsColors = [
    colors.mobaBlue,
    colors.mobaOrange,
    colors.grey67,
    colors.mobaOrange,
  ];

  const series = useMemo(() => {
    return mapToSeries(groups, machineStopsColors);
  }, [groups, machineStopsColors]);

  const newOptions = useMemo(() => {
    return {
      defs: {
        patterns: getPatterns(machineStopsColors),
      },
      chart: {
        type: "column",
      },
      colors: machineStopsColors,
      credits: {
        enabled: false,
      },
      plotOptions: {
        label: {
          enabled: false,
        },
        column: {
          pointPlacement:
            options.selectedDatePeriod === DatePeriod.Day
              ? "between"
              : undefined,
          stacking: "normal",
        },
      },
      series: series,
      title: {
        text: "",
      },
      tooltip: createTooltipFormatter(
        series,
        machineStopsColors,
        options.selectedDatePeriod
      ),
      xAxis: {
        dateTimeLabelFormats: {
          day:
            options.selectedDatePeriod === DatePeriod.Year
              ? "%b %y"
              : "%e %b %y",
          month: "%b %y",
        },
        minorGridLineWidth: 0,
        minorTicks: true,
        showFirstLabel: true,
        showLastLabel: true,
        startOfWeek: 1,
        tickInterval: 0,
        type: "datetime",
      },
      yAxis: {
        min: 0,
        title: {
          text: t("uniqueViews.machineStops.yaxisLabel"),
        },
        labels: {
          formatter: function (): any {
            let value = (this as any).value;
            return Math.round(value / 60);
          },
        },
      },
      ...defaultExportButtonSettings,
    };
  }, [machineStopsColors, options, series]);

  return <HighchartsReact highcharts={Highcharts} options={newOptions} />;
}

function mapToSeries(
  groups: MachineStopsGroup[],
  machineStopsColors: string[]
): any[] {
  let series = [];

  for (let i = 0; i < groups.length; i++) {
    if (groups[i].periods.length === 0) continue;

    const result = {
      name: mapGroupNameToLabel(groups[i].group),
      color:
        groups[i].group === "UnusedDesignatedBreaks"
          ? "url(#custom-pattern-" + i + ")"
          : machineStopsColors[i],
      type: "column",
      data: groups[i].periods.map((item: MachineStopsPeriod) => [
        item.hour.getTime(),
        item.value,
      ]),
    };

    series.push(result);
  }

  return series;
}

function getPatterns(patternColors: string[]) {
  return patternColors.map(function (color, i) {
    return {
      id: "custom-pattern-" + i,
      path: {
        d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
        stroke: color,
      },
    };
  });
}

function mapGroupNameToLabel(groupName: string) {
  switch (groupName) {
    case "UsedDesignatedBreaks":
      return t("uniqueViews.machineStops.usedDesignatedBreaksSeriesName");
    case "UnusedDesignatedBreaks":
      return t("uniqueViews.machineStops.unusedDesignatedBreaksSeriesName");
    case "MachineStop":
      return t("uniqueViews.machineStops.machineStopSeriesName");
    case "StaffBreak":
      return t("uniqueViews.machineStops.staffBreakSeriesName");
  }
}

function createTooltipFormatter(
  series: any,
  machineStopColors: string[],
  selectedDatePeriod: DatePeriod
) {
  return new TooltipBuilder()
    .addXProductionSubCharts(selectedDatePeriod)
    .add((data: any) => {
      let parts = "";
      if (data.length < series.length) return parts;
      for (let i = 0; i < series.length; i++) {
        const dataPoint = (series[i].data as any[]).find(
          (x) => x[0] === data.x
        );
        if (dataPoint === undefined) return parts;
        parts += format(
          {
            label: `<span style="color:${
              series[i].name === "Unused Designated Breaks"
                ? "url(#custom-pattern-" + i + ")"
                : machineStopColors[i]
            }">\u25CF</span> ${series[i].name}`,
            separator: true,
          },
          getDaysAndHoursAndMinutesAndSecondsLabel(
            getDaysAndHoursAndMinutesAndSecondsFromMinutes(dataPoint[1] / 60)
          )
        );
      }

      return parts;
    })
    .buildSharedFormatter();
}

export type MachineStopsChartProps = {
  groups: MachineStopsGroup[];
  options: IPerformanceProFilterOptions;
};
