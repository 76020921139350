import { isMobile } from "react-device-detect";
import { colors } from "../../../colors";
import { everyMinute } from "../../../utility/DateUtility";

export const BarChartSettingsHour = {
  chart: {
    type: "column",
  },
  colors: [colors.machineRunning],
  plotOptions: {
    column: {
      pointPadding: 0,
      grouping: false,
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "",
    },
  },
  xAxis: {
    type: "datetime",
    minorTicks: true,
    showFirstLabel: true,
    showLastLabel: true,
    tickInterval: 0,
    minorGridLineWidth: 0,
  },
  series: [
    {
      data: [],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

export const BarChartSettingsMinute = {
  ...BarChartSettingsHour,
  xAxis: {
    ...BarChartSettingsHour.xAxis,
    tickInterval: everyMinute,
  },
};

export const defaultExportButtonSettings = {
  exporting: {
    enabled: !isMobile,
    buttons: {
      contextButton: {
        menuItems: [
          "printChart",
          "separator",
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          "separator",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
  },
};

export const defaultLegendSettings = {
  legend: {
    enabled: false,
  },
};

export const defaultNavigationSettings = {
  navigation: {
    buttonOptions: {
      enabled: true,
    },
  },
};
