import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import React, { useMemo } from "react";
import { determineYAxisMax } from "../../utility/ChartsUtility";
import { FindProductionTimeFrame } from "../../utility/DataPeriodUtility";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

function CreateTimeFrameInHours(
  data: any,
  productionStartTime: Date,
  productionEndTime: Date
) {
  return data.filter(
    (x: any) =>
      x.dateTime >= productionStartTime && x.dateTime <= productionEndTime
  );
}

function MapToChartData(
  data: any,
  yAxisKey: string,
  productionStartTime?: Date,
  productionEndTime?: Date
) {
  const timeFrame =
    !productionStartTime || !productionEndTime
      ? FindProductionTimeFrame(data, yAxisKey)
      : CreateTimeFrameInHours(data, productionStartTime, productionEndTime);

  const result = {
    data: timeFrame.map((item: any) => ({
      x: item.dateTime,
      y: item[yAxisKey],
    })),
  };

  return {
    series: result,
  };
}

function createPeriodOfEmptyData(startDate: Date, period: number) {
  const result = [];

  // Set current day at 00:00
  const minDate = new Date(startDate);
  minDate.setHours(0, 0, 0, 0);

  for (let index = 0; index < period; index++) {
    const xAxisDate = new Date(minDate);
    xAxisDate.setHours(index);
    result.push({
      x: xAxisDate,
      y: 0,
    });
  }

  return result;
}

export type PerformanceProBaseChart = {
  data: any;
  startDate: Date;
  yAxisLabel: string;
  yAxisKey: string;
  yAxisDefaultRange?: number;
  tooltipFormatter: { formatter: (value: any) => string };
  defaultChartSettings: any;
  productionStartTime?: Date;
  productionEndTime?: Date;
  additionalData: any;
};

export default function PerformanceProBaseChart({
  data,
  startDate,
  yAxisLabel,
  yAxisKey,
  yAxisDefaultRange,
  tooltipFormatter,
  defaultChartSettings,
  productionStartTime,
  productionEndTime,
  additionalData,
}: PerformanceProBaseChart) {
  const newOptions = useMemo(() => {
    if (!data || !startDate) return defaultChartSettings;

    const newData = MapToChartData(
      data,
      yAxisKey,
      productionStartTime,
      productionEndTime
    );
    const numberOfHours = 24;

    if (data.length === 0) {
      newData.series.data = createPeriodOfEmptyData(startDate, numberOfHours);
    }

    const series =
      additionalData === undefined
        ? [
            {
              ...defaultChartSettings.plotOptions.column,
              ...newData.series,
            },
          ]
        : [
            {
              ...defaultChartSettings.plotOptions.column,
              ...newData.series,
            },
            ...additionalData,
          ];

    return {
      ...defaultChartSettings,
      plotOptions: {
        ...defaultChartSettings.plotOptions,
        column: {
          ...defaultChartSettings.plotOptions.column,
        },
      },
      xAxis: {
        ...defaultChartSettings.xAxis,
      },
      series: series,
      yAxis: {
        title: {
          text: yAxisLabel,
        },
        min: 0,
        max: determineYAxisMax(data, yAxisDefaultRange),
      },
      tooltip: tooltipFormatter,
    };
  }, [
    data,
    startDate,
    yAxisLabel,
    tooltipFormatter,
    yAxisKey,
    yAxisDefaultRange,
    defaultChartSettings,
    productionStartTime,
    productionEndTime,
    additionalData,
  ]);

  return <HighchartsReact highcharts={Highcharts} options={newOptions} />;
}
